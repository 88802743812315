import React, { useEffect, useState } from 'react';
import useGetOemInfo from './useGetOemInfo';
import OemItem from './OemItem/OemItem';
import { OemId } from 'helpers/OemId';

interface OemsProps {
    mode;
    oemsConfiguration: OemId[] | undefined;
    fetchCounts: (context: string) => Promise<Record<string, object>>;
    context: 'tagger' | 'ingestionManager';
}

const Oems = ({ mode, oemsConfiguration, fetchCounts, context }: OemsProps) => {
    const { oemInfo } = useGetOemInfo(mode?.isInTagger ? undefined : mode);
    const [notificationCount, setNotificationCount] = useState({});

    // Fetch notification counts when the component mounts
    useEffect(() => {
        const fetchCountsData = async () => {
            const counts = await fetchCounts(context);
            setNotificationCount(counts);
        };

        fetchCountsData();
    }, [fetchCounts, context, mode, oemInfo, oemsConfiguration]);

    const oems = oemsConfiguration ? oemInfo.filter(oem => oemsConfiguration.includes(oem.oemId)) : oemInfo;

    return (
        <div className="container mt-5">
            <span className="font-weight-bold">Choose a Make</span>
            <div className="mt-5 d-flex flex-wrap">
                {Object.values(oems)
                    .sort((a, b) => {
                        if (b.isActive && !a.isActive) return 1;
                        else if (!b.isActive && a.isActive) return -1;
                        else if (a.oemName > b.oemName) return 1;
                        return -1;
                    })
                    .map(oem => {
                        const count = notificationCount[oem.oemId] || {};
                        const badgeText = count.newVehicleCount
                            ? `${count.newVehicleCount} New!`
                            : count.activeFlagsCount || '';

                        return (
                            <OemItem
                                key={oem.oemId}
                                disabled={!oem.isActive}
                                oemInfo={oem}
                                mode={mode}
                                badgeText={badgeText}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default Oems;
